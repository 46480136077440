<template>
  <div>
    <div id='background'></div>

    <div class="section vision-hall-container">
      <div class="container-fluid p-0">
        <!-- selfie -->
        <div class="row justify-content-center selfie-body">


          <div class="col-xl text-center d-none d-xl-block pt-3 pb-3 pr-0 pl-0">
            <div>
              <div v-for="(item,index) in topList" @click="openPledgeDialog(item)"
                   v-if="index <= 1"
                   class="tier__top"

                   v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
              </div>
            </div>

            <div class="margin__left">
              <div v-for="(item,index) in topList" @click="openPledgeDialog(item)"
                   v-if="index >= 2 && index <= 3"
                   class="tier__top"

                   v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
              </div>
            </div>


            <div>
              <div v-for="(item,index) in topList" @click="openPledgeDialog(item)"
                   v-if="index >= 4 && index <= 5"
                   class="tier__top"

                   v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
              </div>
            </div>

            <div class="margin__left">
              <div v-for="(item,index) in topList" @click="openPledgeDialog(item)"
                   v-if="index >= 6 && index <= 7"
                   class="tier__top"

                   v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
              </div>
            </div>
          </div>

          <div class="col-12 col-xl pt-3 pb-3 pt-sm-3 pb-sm-3">
            <div class="row align-items-center justify-content-center content-center">
              <div class="col-12">
                <div class="title ">
                  MISC <br> VISION HALL
                </div>

                <div class="row justify-content-center">
                  <div class="col-auto" style="max-width: 400px">
                    <div class="mb-3 sub-title text-center">
                      With the roll out of MISC 2050, share your selfie pledge and aspiration with
                      us.

                    </div>
                  </div>
                </div>


                <div class="text-center">
                  <div v-if="isMobile()">
                    <div class="row justify-content-center"
                         style="background-image: url('../assets/image/icon_camera.png');">
                      <div class="col-12 col-sm-auto">
                        <img @click="$refs.selfie.click()"
                             class="img-fluid img-upload"
                             src="../assets/image/selfie.png">
                        <input ref="selfie" type="file" @change="selfie_upload($event)"
                               accept="image/*;capture=camera"
                               style="display: none">
                      </div>
                    </div>
                  </div>


                  <div v-else>
                    <div class="row justify-content-center align-items-center no-gutters flex-nowrap"
                         style="position: relative">
                      <div class="col-12 col-sm-auto">
                        <img v-b-modal.modal-takephoto-dialog
                             class="img-fluid img-upload"
                             src="../assets/image/selfie.png">
                      </div>
                    </div>
                  </div>
                </div>


                <div class="text-center">
                  <img class="img-fluid img-spaceship"
                       src="../assets/image/spaceship.png">
                </div>

                <div>
                  <div id="see-more"> SCROLL DOWN TO VIEW MORE
                    <div class="text-center">
                      <img class="icon-see-more"
                           src="../assets/image/icon-see-more.png">
                    </div>
                  </div>
                </div>


                <selfie-desktop-dialog :img_file="img_file" :img_preview="img_preview"
                                       :img_type="img_type"
                                       @openPledgeDialog="openPledgeDialog"/>
                <take-photo-dialog @takePhoto="takePhoto"/>
                <selfie-dialog :img_file="img_file" :img_preview="img_preview"
                               @openPledgeDialog="openPledgeDialog"/>
                <pledge-dialog :data="selectedPledge"/>


              </div>
            </div>
          </div>

          <div class="col-xl text-center d-none d-xl-block pt-3 pb-3 pr-0 pl-0">
            <div>
              <div v-for="(item,index) in topList" @click="openPledgeDialog(item)"
                   v-if="index <= 1"
                   class="tier__top"

                   v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
              </div>
            </div>

            <div class="margin__right">
              <div v-for="(item,index) in topList" @click="openPledgeDialog(item)"
                   v-if="index >= 2 && index <= 3"
                   class="tier__top"

                   v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
              </div>
            </div>


            <div>
              <div v-for="(item,index) in topList" @click="openPledgeDialog(item)"
                   v-if="index >= 4 && index <= 5"
                   class="tier__top"

                   v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
              </div>
            </div>

            <div class="margin__right">
              <div v-for="(item,index) in topList" @click="openPledgeDialog(item)"
                   v-if="index >= 6 && index <= 7"
                   class="tier__top"

                   v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
              </div>
            </div>
          </div>

        </div>
        <!-- end selfie -->

        <!-- selfie list -->
        <div class="row no-gutters justify-content-center align-items-center">

          <div class="col-auto text-center">


            <div v-for="(items, parentIndex) in list">
              <div v-if="parentIndex%2 === 0" style="white-space: nowrap">
                <div v-for="(item,index) in items" @click="openPledgeDialog(item)" class="fade-in"
                     v-bind:class="tierClass(parentIndex * itemPerList + index)"
                     v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
                </div>
              </div>

              <div v-else class="selfie__margin__left" style="white-space: nowrap ">
                <div v-for="(item,index) in items" @click="openPledgeDialog(item)" class="fade-in"
                     v-bind:class="tierClass(parentIndex * itemPerList + index)"
                     v-bind:style="{ backgroundImage: 'url(' + `${apiUrl}/assets/uploads/${item.profile_image}` + ')' }">
                </div>
              </div>
            </div>


            <div style="overflow: auto;">
              <infinite-loading force-use-infinite-wrapper="true" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </div>


          </div>

        </div>

      </div>
      <!-- end selfie -->


    </div>
  </div>

</template>


<script>
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    if (currentScrollPos === 0) {
      document.getElementById("see-more").style.display = "block";
    } else {
      document.getElementById("see-more").style.display = "none";
    }
    prevScrollpos = currentScrollPos;
  };

  import store from '../store';
  import {mapState} from 'vuex';
  import SelfieDialog from "../components/Selfie";
  import PledgeDialog from "../components/Pledge";
  import {GET_SELFIE_LIST, GET_TOTAL_SELFIE, GET_USER_INFO} from "../store/actions.type";
  import {API_URL} from '../config/constant'
  import * as _ from 'underscore';
  import TakePhotoDialog from "../components/TakePhoto";
  import SelfieDesktopDialog from "../components/SelfieDesktop";
  import axios from 'axios';

  export default {
    components: {SelfieDesktopDialog, TakePhotoDialog, PledgeDialog, SelfieDialog},
    computed: {
      ...mapState({
        user: state => state.auth.user,
      }),
    },
    data: function () {
      return {
        form: {},
        img_file: null,
        img_preview: null,
        img_type: null,
        total: 200,
        topList: [],
        list: [],
        itemPerList: 15,
        start: 0,
        end: 100,
        apiUrl: API_URL,
        selectedPledge: {},
        tierState: null
      }
    },
    async created() {

      // set header
      axios.defaults.headers.common['Authorization'] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjciLCJuYW1lIjoiTmF0dGFyaW4iLCJzdGFmZl9pZCI6IjEyMzQ1IiwiZGVwYXJ0bWVudCI6IlBFIiwiZW1haWwiOiJuYXR0YXJpbkBkaWdpbWFnaWMuY29tLnNnIiwibW9iaWxlX25vIjoiNjY4NDU1NDQ0MTUiLCJlbmFibGUiOiIxIiwicm9sZSI6InVzZXIiLCJjcmVhdGVfZGF0ZSI6IjIwMjAtMTItMjUgMTA6NDU6MTAiLCJsYXN0X2xvZ2luIjoiMjAyMS0wMS0xNiAxNDo1OTo1MCJ9.3vF4a-ORXVPeUrdv0iqI_EHw7Ijg5F1-qtojDMil9Iw`;
      await store.dispatch(GET_USER_INFO);


      this.init();
    },
    methods: {
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },

      async init() {
        this.list = [];
        this.start = 0;
        this.end = 100;

        // let totalPledge = await store.dispatch(GET_TOTAL_SELFIE);
        // this.total = totalPledge.total || 0;

        let result = await store.dispatch(GET_SELFIE_LIST, {start: this.start, end: this.end});
        this.topList = result;

        if (result.length > 0) {
          this.start = 0;
          this.end = 100;

          let result_chunk = _.chunk(_.shuffle(result), this.itemPerList);
          this.list.push(...result_chunk);
        }
      },

      tierClass: function (index) {
        let tier = ((index + 1) / this.total) * 100;
        if (tier <= 25) {
          this.tierState = 'tier__1';
          return 'tier__1';
        } else if (tier <= 50) {
          this.tierState = 'tier__2';
          return 'tier__2';
        } else if (tier <= 75) {
          this.tierState = 'tier__3';
          return 'tier__3';
        } else {
          this.tierState = 'tier__4';
          return 'tier__4';
        }
      },

      selfie_upload(event) {
        // Reference to the DOM input element
        let input = event.target;
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files[0]) {

          // set img params
          this.img_file = input.files[0];

          // create a new FileReader to read this image and convert to base64 format
          let reader = new FileReader();
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
            // Read image as base64 and set to imageData
            this.img_preview = e.target.result;
          };
          // Start the reader job - read file as a data url (base64 format)
          reader.readAsDataURL(input.files[0]);
        }

        this.$bvModal.show('modal-selfie');
      },

      setItemPerPageFromTierState(tier) {
        if (window.innerWidth > 576) {
          switch (tier) {
            case 'tier__1':
              this.itemPerList = 15;
              return;
            case 'tier__2':
              this.itemPerList = 20;
              return;
            case 'tier__3':
              this.itemPerList = 25;
              return;
            case 'tier__4':
              this.itemPerList = 30;
              return;
          }
        } else {
          switch (tier) {
            case 'tier__1':
              this.itemPerList = 5;
              return;
            case 'tier__2':
              this.itemPerList = 8;
              return;
            case 'tier__3':
              this.itemPerList = 12;
              return;
            case 'tier__4':
              this.itemPerList = 12;
              return;
          }
        }
      },

      async infiniteHandler($state) {
        let result = await store.dispatch(GET_SELFIE_LIST, {start: this.start, end: this.end});

        if (result.length > 0) {
          this.start = 0;
          this.end = 100;

          this.setItemPerPageFromTierState(this.tierState);

          let lastCurrentResult = this.list[this.list.length - 1];
          let lastCurrentResultLength = lastCurrentResult.length;

          let quotaLastIndex = this.itemPerList - lastCurrentResultLength;
          if (quotaLastIndex >= 0) {
            let popResult = result.splice(0, quotaLastIndex);
            if (popResult.length > 0) {
              lastCurrentResult.push(...popResult);
            }

          }
          let result_chunk = _.chunk(_.shuffle(result), this.itemPerList);
          this.list.push(...result_chunk);

          $state.loaded();
        } else {
          $state.complete();
        }
      },

      openPledgeDialog(data) {
        this.selectedPledge = data;
        this.$bvModal.show('modal-pledge');

        if (data.img_type === "base64") {
          this.init();
        }
      },


      async takePhoto(data) {
        this.img_file = data.img_file;
        this.img_preview = data.img;
        this.img_type = data.img_type;

        this.$bvModal.hide('modal-takephoto-dialog');
        setTimeout(() => {
          this.$bvModal.show('modal-selfie-desktop');
        }, 1000);

      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../assets/css/colors";

  .selfie__margin__left {
    margin-left: -75px;
  }

  #background {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    margin: 0;
    background: url('../assets/image/background_vision_hall.png') center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .vision-hall-container {
    color: white;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }

  .selfie-body {
    min-height: 100vh;
  }

  .content-center {
    min-height: 100vh;
  }

  .img-spaceship {
    max-height: 250px;
    margin-top: -10%;
  }

  .img-upload {
    max-height: 250px;
  }

  .img-upload:hover {
    background: radial-gradient(circle at center,
      rgba(34, 193, 195, 0.3) 40%,
      rgba(0, 0, 0, 0) 70%
    );
  }

  @media (max-width: 576px) {
    .img-spaceship {
      margin-top: -10%;
      max-height: 250px;
    }

    .content-center {
      min-height: unset;
    }

    .img-upload {
      max-height: 250px;
    }
  }

  .tier__top {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin: 3px;
    display: inline-block;
    vertical-align: middle;
    z-index: -1;
  }

  @media (min-width: 1200px) {
    .tier__top {
      width: 180px;
      height: 180px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 3px;
      display: inline-block;
      vertical-align: middle;
      z-index: -1;
    }

    .margin__left {
      margin-left: -200px;
    }

    .margin__right {
      margin-right: -200px;
    }
  }

  @media (min-width: 1285px) {
    .tier__top {
      width: 200px;
      height: 200px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 3px;
      display: inline-block;
      vertical-align: middle;
      z-index: -1;
    }

    .margin__left {
      margin-left: -250px;
    }

    .margin__right {
      margin-right: -250px;
    }
  }

  @media (min-width: 1500px) {
    .tier__top {
      width: 220px;
      height: 220px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 3px;
      display: inline-block;
      vertical-align: middle;
      z-index: -1;
    }

    .margin__left {
      margin-left: -300px;
    }

    .margin__right {
      margin-right: -300px;
    }
  }


  @media (min-width: 1800px) {
    .tier__top {
      width: 240px;
      height: 240px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 3px;
      display: inline-block;
      vertical-align: middle;
      z-index: -1;
    }
  }

  .tier__1 {
    width: 180px;
    height: 180px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin: 3px;
    display: inline-block;
    vertical-align: middle;
  }

  .tier__2 {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin: 3px;
    display: inline-block;
    vertical-align: middle;
  }

  .tier__3 {
    width: 120px;
    height: 120px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin: 3px;
    display: inline-block;
    vertical-align: middle;
  }

  .tier__4 {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin: 3px;
    display: inline-block;
    vertical-align: middle;
  }


  @media (max-width: 576px) {
    .tier__1 {
      width: calc((100vw / 3) - 6px);
      height: calc((100vw / 3) - 6px);
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 3px;
      display: inline-block;
      vertical-align: middle;
    }

    .tier__2 {
      width: calc(100vw / 4.2);
      height: calc(100vw / 4.2);
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 3px;
      display: inline-block;
      vertical-align: middle;
    }

    .tier__3 {
      width: calc(100vw / 5.2);
      height: calc(100vw / 5.2);
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 3px;
      display: inline-block;
      vertical-align: middle;
    }

    .tier__4 {
      width: calc(100vw / 5.2);
      height: calc(100vw / 5.2);
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 3px;
      display: inline-block;
      vertical-align: middle;
    }

    .selfie__margin__left {
      margin-left: -15%;
    }
  }


  [class*="tier__"]:hover {
    cursor: pointer;
    transform: translateY(-10px);

  }


  .speech-bubble {
    padding: 20px 15px;
    position: relative;
    background: rgba(26, 65, 183, 0.7);
    color: $primary;
    line-height: 1.8;
    font-family: Akrobat, sans-serif;

    .text-white {
      font-weight: bold;
    }
  }

  .speech-container {
    z-index: 1;
    width: 220px;
  }

  .speech-bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 35px solid transparent;
    border-top-color: rgb(26, 65, 183, 0.8);
    border-bottom: 0;
    border-right: 0;
    margin-left: 45px;
    margin-bottom: -35px;
  }

  .speech {
    margin-top: 40px;
    z-index: 1;
    font-family: Akrobat, sans-serif;

    .name {
      font-family: Akrobat, sans-serif;
      font-weight: bold;
      font-size: 20px;
    }

    .position {
      font-size: 16px;
    }
  }

  .title {
    font-family: Taget, sans-serif;
    font-weight: 600;
    color: #00aade;
    font-size: 40px;
    line-height : 1;
  }

  .sub-title {
    font-weight: 600;
    color: #bebebe;
    font-size: 20px;
    line-height: 1.2;
  }

  #see-more {
    line-height: 1.2;
    position: fixed;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    font-weight: bold;
    color: #00aade;
    text-align: center;
    width: 100%;
    text-shadow: 2px 2px 2px $black;
  }

  .icon-see-more {
    height: 10px;
    text-align: center;
  }

  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
